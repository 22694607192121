.App {
  height: 100vh;
  background-size: cover;
}

.container {
  height: 100%;
  background: linear-gradient(92.31deg, rgba(221, 102, 173, 0.45) 0%, rgba(224, 87, 180, 0.45) 33.85%, rgba(206, 101, 139, 0.45) 49.48%, rgba(185, 63, 183, 0.45) 59.37%, rgba(129, 86, 160, 0.45) 100%);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.box {
  padding: 20px;
  background-color: #FFFFFFB3;
  backdrop-filter: blur(8px);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.days {
  width: fit-content;
  font-size: 8rem;
  line-height: 200px;
  background-image: linear-gradient(106.13deg, #D70A84 23.5%, #51127F 100%);
  background: conic-gradient(...);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 margin: 0;
}
